import { OPENLOADING, CLOSELOADING } from './actionType'

let defaultState = {
    loading: false
}

export default function todoReducer(state = defaultState, action) {
    let newObj = { ...state }
    switch (action.type) {
        case OPENLOADING: {
            newObj.loading = true
            return newObj
        }
        case CLOSELOADING: {
            newObj.loading = false
            return newObj
        }
        default:
            return state
    }
}
